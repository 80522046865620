import Layout from "../../components/Layout/Layout"
import "./Home.css"

export default function Home() {
  
  return (
    <Layout>
      <div className="home-container">
        <img src={`${process.env.REACT_APP_LOGO}`} className="logo" alt="logo" />
        <span className="greetings-span">
          Thanks for visiting Cakes by Mayra, home bakery located in Arlington, TX where you can order custom cookies and cakes.
        </span>
          <hr />
        <div className="home-pics-div">
          <img src={`${process.env.REACT_APP_COOKIES_PREFIX}/${process.env.REACT_APP_PIC1}`} alt="cookie" className="home-pics"/>
          <img src={`${process.env.REACT_APP_COOKIES_PREFIX}/${process.env.REACT_APP_PIC2}`} alt="cookie" className="home-pics"/>
          <img src={`${process.env.REACT_APP_COOKIES_PREFIX}/${process.env.REACT_APP_PIC3}`} alt="cookie" className="home-pics"/>
          <img src={`${process.env.REACT_APP_CAKES_PREFIX}/${process.env.REACT_APP_PIC4}`} alt="cake" className="home-pics"/> 
          <img src={`${process.env.REACT_APP_CAKES_PREFIX}/${process.env.REACT_APP_PIC5}`} alt="cake" className="home-pics"/>
          <img src={`${process.env.REACT_APP_CAKES_PREFIX}/${process.env.REACT_APP_PIC6}`} alt="cake" className="home-pics"/>
        </div>
      </div>
    </Layout>
  )
}

