import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home/Home";
import CakeForm from "./screens/CakeForm/CakeForm";
import CookieForm from "./screens/CookieForm/CookieForm";
import CakeGallery from "./screens/CakeGallery/CakeGallery";
import CookieGallery from "./screens/CookieGallery/CookieGallery";

function App() {
	return (
		<div className="app">
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/cake-inquiry" element={<CakeForm />} />
				<Route path="/cookie-inquiry" element={<CookieForm />} />
				<Route path="/cake-gallery" element={<CakeGallery />} />
				<Route path="/cookie-gallery" element={<CookieGallery />} />
			</Routes>
		</div>
	);
}

export default App;
