import "./Footer.css"

export default function Footer() {
  return (
    <div className="footer-div">
      <a href="https://www.facebook.com/profile.php?id=61553233025931" target="_blank" rel="noreferrer"><img src="https://img.icons8.com/?size=96&id=yGcWL8copNNQ&format=png" alt="facebook-logo" className="ig-logo" /></a>
      <a href="https://www.instagram.com/cakesbymayraa/" target="_blank" rel="noreferrer"><img src="https://img.icons8.com/?size=160&id=nj0Uj45LGUYh&format=png" alt="instagram-logo" className="ig-logo" /></a>
      <a href="https://www.tiktok.com/@cakesbymayraa" target="_blank" rel="noreferrer"> <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTsprTi8lIGnIp_BlIg5V03SWyaoHNteAFiGkqkSu7GsnnmnhmZQ-UYOQNBdtVGqKb7JdU&usqp=CAU" alt="tiktok-logo" className="tiktok-logo"/></a>
    </div>
  )
}
