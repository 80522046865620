import api from "./apiConfig";

export const createCakeClient = async (post) => {
	try {
		const response = await api.post(`/cake-clients`, post);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getCakeClients = async () => {
	try {
		const responce = await api.get("/cake-clients");
		return responce.data;
	} catch (error) {
		throw error;
	}
};

export const getCakeClient = async (id) => {
	try {
		const response = await api.get(`/cake-clients/${id}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateCakeClient = async (id, body) => {
	try {
		const responce = await api.put(`cake-clients/${id}`, body);
		return responce.data;
	} catch (error) {
		throw error;
	}
};

export const deleteCakeClient = async (id) => {
	try {
		const response = await api.delete(`/cake-clients/${id}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

///////////////////////////////////////////////////////////

export const createCookieClient = async (post) => {
	try {
		const response = await api.post(`/cookie-clients`, post);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const getCookieClients = async () => {
	try {
		const responce = await api.get("/cookie-clients");
		return responce.data;
	} catch (error) {
		throw error;
	}
};

export const getCookieClient = async (id) => {
	try {
		const response = await api.get(`/cookie-clients/${id}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

export const updateCookieClient = async (id, body) => {
	try {
		const responce = await api.put(`cookie-clients/${id}`, body);
		return responce.data;
	} catch (error) {
		throw error;
	}
};

export const deleteCookieClient = async (id) => {
	try {
		const response = await api.delete(`/cookie-clients/${id}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};
