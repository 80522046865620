import Footer from "../Footer/Footer"
import Nav from "../Nav/Nav"
import "./Layout.css"

export default function Layout({children}) {
  return (
    <div >
      <Nav />
      <div>{children}</div>
      <Footer/>
    </div>
  )
}
