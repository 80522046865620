import "./Nav.css"
import { NavLink } from "react-router-dom";

export default function Nav() {
  function myFunction(e) {
    e.preventDefault()
  let x = document.getElementById("myLinks");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}
  return (
    <>
      <nav className="nav pc-version">
      <NavLink to="/" className="name">Cakes By Mayra</NavLink>
      <NavLink to="/" className="home-link">HOME</NavLink>
      <NavLink to="/cake-gallery" className="cake-gallery-link">CAKE GALLERY</NavLink>
      <NavLink to="/cookie-gallery" className="cookie-gallery-link">COOKIE GALLERY</NavLink>
      <NavLink to="/cake-inquiry" className="form-link">CAKE ORDER INQUIRY</NavLink>
      <NavLink to="/cookie-inquiry" className="form-link">COOKIE ORDER INQUIRY</NavLink>
      </nav>
      <div className="topnav">
        <NavLink to="/"><p className="nav-name">Cakes By Mayra</p></NavLink>
        <div id="myLinks">
          <NavLink to="/" className="home-link">HOME</NavLink>
      <NavLink to="/cake-gallery" className="cake-gallery-link">CAKE GALLERY</NavLink>
      <NavLink to="/cookie-gallery" className="cookie-gallery-link">COOKIE GALLERY</NavLink>
      <NavLink to="/cake-inquiry" className="form-link">CAKE ORDER INQUIRY</NavLink>
      <NavLink to="/cookie-inquiry" className="form-link">COOKIE ORDER INQUIRY</NavLink>
        </div>
        <a href="/" class="icon" onClick={(e)=>myFunction(e)}>
          <i className="fa fa-bars"></i>
        </a>
        </div>
      </>
  )
}
